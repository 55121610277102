<template>
    <div class="useCase-page">
        <base-nav />
        <base-inner-banner img-type="application"  />

        <b-container class="position-relative">
            <h2 class="text-center page-h1 mb-0">{{ $t('Nav_useCases')}}</h2>
            <div class="d-flex" style="justify-content: space-between;margin-left:auto;margin-right:auto;width:71%">
                <img class="quotation-mark" src="@/assets/img/usecase/double-quotation-mark-png.png" loading="lazy" alt="">
                <h2 class="useCase-slogan">
                    {{ $t('UseCases.slogan')}}
                </h2>
                <img class="quotation-mark-upside-down" src="@/assets/img/usecase/double-quotation-mark-png.png" loading="lazy" alt="">
            </div>
            
            
            <div class="row useCase-layout" >
                <div class="col-lg-6 col-sm-12 col-md-6">
                    <p class="useCase-description">
                        {{ $t('UseCases.description')}}
                    </p>
                </div>
                <div class="col-lg-6 col-sm-12 col-md-6" style="text-align:center">
                    <img class="useCase-logo" src="@/assets/img/usecase/gold-partner.png" loading="lazy" alt="aira城智科技，榮獲intel認證的AI Gold Partner，台灣自主研發的人臉辨識系統廠商">
                </div>
            </div>
            
            
            
            <template>
                
                <b-row class="category-useCase position-relative">
                        <div
                            v-for="useCase in useCaseList"
                            :key="useCase._id"
                            class="useCase-col"
                            >
                            <use-case-item
                                :useCase="useCase"
                                item-direction="column" /> 
                        </div>
                </b-row>
        
            </template>

            <use-case-comments /> 
            
        </b-container>

        <base-contact style="background-color: #ffffff;"/>

        <base-footer />


    </div>
</template>

<script>
import useCaseItem from '../../components/useCase/useCaseItem.vue'
import smartBuilding from "../../assets/img/usecase/smart-building.webp"
import smartGym from "../../assets/img/usecase/smart-gym.webp"
import smartOffice from "../../assets/img/usecase/smart-office.webp"
import smartCamp from "../../assets/img/usecase/smart-camp.webp"
import smartCare from "../../assets/img/usecase/smart-care.webp"
import smartConstructionSite from "../../assets/img/usecase/smart-construction-site.webp"
import UseCaseComments from '../../components/useCase/useCaseComments.vue'
const defaultState = () => {
    return {
        useCaseList: [
        {
            "_id": 1,
            "cover": smartBuilding,
            "title" : "智慧建築",
            "description":"隨著科技驅使以及後疫情時代來臨, 各大企業都在追求智慧化的應用。aira使用人臉辨識應用作為主軸，將解決方案深入各種管理問題，並提供最有效率以及彈性的智慧建築解決方案，讓智慧建築成為企業成長的最佳利器。",
            "industries":"smartBuilding",
        },
        {
            "_id": 2,
            "cover": smartConstructionSite,
            "title" : "智慧工地",
            "description":"如何在運行多個建案和專案的同時，有效預防人為的管理疏失所造成的工安意外、防疫漏洞和偷竊事件，一直是業主想要解決的問題。aira的智慧工地的解決方案，讓總部能即時掌握工地情況，改善資源調度的彈性和提高工地安全。",
            "industries":"smartConstructionSite"
        },
        {
            "_id": 3,
            "cover": smartOffice,
            "title" : "智慧辦公",
            "description":"當疫情席捲世界，如何讓辦公室變得更加智能和安全，讓員工更願意回來上班？透過導入aira人臉辨識整合系統，讓防疫和人員考勤不再是件惱人的事情，為員工創造一個安全的避風港。",
            "industries":"smartOffice"
        },
        {
            "_id": 4,
            "cover": smartCare,
            "title" : "智慧長照",
            "description":"人力短缺一直是長照產業所面臨的問題。每當突然的意外發生或是院所內的老人走失，都對照顧中心增加不少負擔。aira使用人臉辨識技術為主軸，將解決方案深入長照的各種管理問題，讓智慧長照釋放中心緊繃的人力。",
            "industries":"smartCare"
        },
        {
            "_id": 5,
            "cover": smartGym,
            "title" : "智慧健身房",
            "description":"大型場館和會員數眾多的健身房，往往面臨各種錯綜複雜的問題，如防疫、員工考勤、會員管理、偷竊或騷擾事件。透過導入aira人臉辨識整合系統，降低人員費用，並維持高品質和安全的運動場域。",
            "industries":"smartGym"
        },
        {
            "_id": 6,
            "cover": smartCamp,
            "title" : "智慧校園",
            "description":"校園作為重要的學習和研究機構，除了投入大量的資源追求教學品質外，疫情時期更須管理訪客、師生出勤和健康情況。aira作為台灣自主研發的人臉辨識技術廠商，在保護個資安全的前提下，打造既智慧又安全的校園。",
            "industries":"smartCamp"
        }
        
        
    ],

    }
}


export default {
    name: 'UseCases',
    metaInfo() {
        return {
            title:this.$t('UseCases.Meta_Management.UseCases.title'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('UseCases.Meta_Management.UseCases.description')
                },
                {
                    name: 'keywords',
                    content: this.$t('UseCases.Meta_Management.UseCases.description')
                }
            ]
        }
    },
    
    components: { useCaseItem, UseCaseComments },

    data() {
        return Object.assign({}, defaultState())
    },
    created(){
        // this.testUseCase();
        window.scrollTo(0,0);
        
    },
    methods: {
        testUseCase(){
            const me = this;
            const useCases = me.$t("UseCases");
            me.useCaseList =  useCases;
            // console.log("test useCaseList",this.useCaseList)
        }
    }
}
</script>

<style lang="scss" scoped>

.useCase-page{
    background: #f6f6f6;
}

.useCase-layout{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.useCase-logo {
    // width: 400px;
    max-width: 100%;
}
.useCase-description {
    width: 68%;
    font-size: 25px;
    margin-left:  auto;
    margin-right: auto;
    line-height: 4rem;
}

.useCase-slogan{
    font-size: 35px;
    text-align: center;
}

.quotation-mark {
    width: 30px;
    height: 30px;
}
.quotation-mark-upside-down{
    width: 30px;
    height: 30px;
    transform:rotate(180deg)
}
$useCase-col-padding: 8px;

.category-useCase{
    margin-left: -$useCase-col-padding;
    margin-right: -$useCase-col-padding;
    margin-bottom: 40px;
}


.useCase-col{
    padding: 0 $useCase-col-padding;
    flex: 0 0 33.3%;
    margin-bottom: 40px;
    max-width: 33.3%;
}

@media screen and (max-width: 812px) {
    .useCase-slogan{
        font-size: 30px;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
    .useCase-description {
        width: 90%;
        font-size: 20px;
        line-height: 3rem;
    }
    .useCase-col{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .quotation-mark {
        width: 20px;
        height: 20px;
    }
    .quotation-mark-upside-down{
        width: 20px;
        height: 20px;
    }

    
}


@media screen and (max-width: 576px) {
    .useCase-slogan{
        font-size: 20px;
        width: 85%;
    }
    .useCase-description {
        width: unset;
        font-size: 19px;
        line-height: 2rem;
    }

    .useCase-col{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .category-useCase{
        margin-bottom: 20px;
    }

    .quotation-mark {
        width: 10px;
        height: 10px;
    }
    .quotation-mark-upside-down{
        width: 10px;
        height: 10px;
    }
}

</style>