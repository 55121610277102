<template>
    <section  class="position-relative overflow-hidden useCases-section">
        <b-container>
            <div class="position-relative">
                <!-- arrow -->
                <!-- <div class="tabs">
                    
                    <div class="mobile-useCase-tab-control">
                        <div class="tab-control-left tab-control-btn" @click="setTabIndex(-1)">
                            <b-icon icon="chevron-left"></b-icon>
                        </div>
                        <div class="tab-control-right tab-control-btn" @click="setTabIndex(1)">
                            <b-icon icon="chevron-right"></b-icon>
                        </div>
                    </div>
                </div> -->

                <div class="swiper-container-useCases overflow-hidden position-relative w-100">
                    <div class="swiper-wrapper">
                        <div v-for="comment in commentList"
                            :key="comment._id"
                            class="swiper-slide">
                            <div  class="useCase-item position-relative overflow-hidden">
                                <p class="useCase-description">
                                    {{comment.description}}
                                </p>
                                <h3 class="useCase-name">
                                    {{comment.name}}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <!-- dots -->
                    <div class="swiper-pagination"></div>
                </div>

            </div>
        </b-container>
    </section>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay, Lazy }  from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

const defaultState = () => {
    return {
        tabIndex: 0,
        tabs: [],
        commentList: [
            {
                "_id": 1,
                "title" : "智慧校園",
                "description":"airaFace可以整合現有門禁系統，辨識精確且導入快速，同時做到人臉辨識打卡與門禁，並有多種儀表板模式，加強管理效率。",
                "name":"知名系統整合商"
            },
            {
                "_id": 2,
                "title" : "智慧安防",
                "description":"airaTrack提供了快速以圖找人的功能，即便是在大型的場域，都能快速地掌握目標人物蹤跡與接觸人，有效提高場域安全。",
                "name":"知名保全公司"
            },
            {
                "_id": 3,
                "title" : "智慧工地",
                "description":"airaTablet mini 方便我們進行工地管理，即使配戴安全帽和口罩時，仍可透過人臉辨識打卡上下班，並連動line通知即時掌握工區情況。",
                "name":"知名建設公司"
            }
        ],
        swiper_aira: null
    }
}

export default {
    name: 'useCaseComments',
    data(){
        return Object.assign({}, defaultState())
    },
    mounted(){
        this.setSlide();
    },
    computed: {
        currentLang() {
            const locale = this.$i18n.locale;
            
            if (locale.includes('en')) {
                return 'en';
            } else {
                return 'zh';
            }
        }
    },
    methods: {
        setSlide() {
            // eslint-disable-next-line
            this.swiper_aira = null;
            let swiper_aira;

            const swiperContainer = document.querySelector('.swiper-container-useCases');

            swiper_aira = new Swiper(swiperContainer, {
                slidesPerView: 1,
                spaceBetween: 6,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                },
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 870px
                    870: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    }
                }
            });
        
            this.swiper_aira = swiper_aira;
        },
    }
    
}
</script>

<style lang="scss" scoped>

.useCase-description{
    font-size: 2rem;
    font-weight: 300;
    line-height: 4rem;
    max-width: 60rem;
    margin-right: auto;
    margin-left: auto;
}
.useCase-name{
    font-size: 2.5rem;
}

.swiper-container-useCases {
    position: relative;
}

.useCases-section{
    position: relative;
    margin-bottom: 70px;
    background-color: #e2e2e2;
}

.mobile-useCase-tab-control{
    display: none;
}

.useCase-item {
    text-align: center;
}

.swiper-slide{
    .useCase-item {
        display: inline-block;
        margin: 8px;
        width: 100%;
    }
}

.useCase-item-title{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    color: #fff;
    background: rgba(0,0,0, 0.7);
    margin-bottom: 0;
    padding: 20px 20px;
    font-size: 20px;
    font-weight: 400;
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 812px) {
    .swiper-slide{
        .useCase-item {
            margin: 8px 0;
            width: 100%;
            // height: 250px !important; 
        }
    }

    .useCase-item{
        &:after {
            display: none;
        }
    }

    .useCase-item-title{
        padding: 15px 16px;
        font-size: 18px;
    }

    .useCase-description{
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 3rem;
        max-width: 60rem;
        margin-right: auto;
        margin-left: auto;
    }

    .useCase-name{
        font-size: 2rem;
    }

}

@media screen and (max-width: 576px) {
    .mobile-useCase-tab-control{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .tab-control-btn{
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        top: 0;
        z-index: 3;
        cursor: pointer;
    }

    .tab-control-right{
        right: 0;
    }

    .tab-control-left{
        left:0;
    }

    .useCase-item {
        &:hover {
            .tab-title {
                color:  transparent;
            }

            .active .tab-title{
                color: $color_primary;
            }
        }
    }

    .useCase-description{
        font-size: 1rem;
        font-weight: 300;
        line-height: 2rem;
        max-width: 60rem;
        margin-right: auto;
        margin-left: auto;
    }

    .useCase-name{
        font-size: 1.5rem;
    }

}
</style>